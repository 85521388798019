/**
 * 示例-列表页
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">列表页示例</div>
        <div class="flex-list">
          <div class="flex-list-item" v-for="(item, index) in list" :key="'item' + index" @click="showDetail(item.subTitle)">
            <p>{{ item.title }}</p>
            <span>{{ item.subTitle }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [
          {
            title: '普通列表页',
            subTitle: 'Default'
          },
          {
            title: '带图片预览',
            subTitle: 'Preview'
          },
          {
            title: '带右侧搜索',
            subTitle: 'FormRight'
          },
          {
            title: '带上方搜索',
            subTitle: 'FormTop'
          },
          {
            title: '带批量操作',
            subTitle: 'Batch'
          },
          {
            title: '带多tab标签',
            subTitle: 'Tab'
          }
        ]
      };
    },
    methods: {
      /**
       * 查看详情
       */
      showDetail(type) {
        this.$router.push({
          name: 'testList' + type
        });
      }
    }
  };
</script>